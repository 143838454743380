
























































































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import importStudents from "@/components/import-students/index.vue";
import ImportExamPaper from "@/components/import-exam/index.vue";
import { ExamPaper, ExamSchool, ExamProjectPro } from "@/tool/_class";
import { queryExamProjectProfile, setExamProject } from "@/api/examProject";
import { deleteProjectExamStudent } from "@/api/examStudent";
import { deleteExamPaper, getExamPapers } from "@/api/examPaper";
import { formatTime } from "@/utils/date";
import * as _ from "lodash";
@Component({
  name: "ExamProjectProfile",
  components: {
    cardBox,
    importStudents,
    ImportExamPaper,
  },
})
export default class extends Vue {
  private showDialogImport: boolean = false;
  private showDialogImportType: string = "";
  private dialogTitle: string = "";
  private title: string = "项目";
  private fromExamProject: ExamProjectPro = new ExamProjectPro();
  private pageType: string = "";
  private disabledImport: boolean = true;
  private editModel: boolean = false;
  private dateEnd: any = {};
  private dateBegin: any = {};
  private get disabledSubmit(): boolean {
    if (
      !this.fromExamProject.examProjectName ||
      !this.fromExamProject.examStartTime ||
      !this.fromExamProject.examEndTime
    ) {
      return true;
    } else {
      return false;
    }
  }

  private get examSchoolCount(): number {
    return this.fromExamProject.examSchools.length;
  }

  private get examStudentCount(): number {
    let tmp: number = 0;
    for (let i = 0; i < this.fromExamProject.examSchools.length; i++) {
      tmp = tmp + this.fromExamProject.examSchools[i].standardExamStudents;
    }
    return tmp;
  }

  private get type(): string {
    if (this.pageType == "see") {
      this.title = "查看考试";
      return "see";
    }
    if (this.pageType == "add") {
      this.title = "创建考试";
      return "立即创建";
    }
    if (this.pageType == "edit") {
      this.title = "编辑考试";
      this.disabledImport = false;
      this.editModel = true;
      return "保存";
    }
    return "立即创建";
  }

  private examSchoolTagLabel(item: ExamSchool) {
    let examRooms = this.fromExamProject.examRooms.filter((examRoom) => {
      return examRoom.schoolId == item.schoolId;
    });
    let schoolTypes: string[] = [];
    if (item.primarySchool) {
      schoolTypes.push("小");
    }
    if (item.middleSchool) {
      schoolTypes.push("中");
    }
    if (item.highSchool) {
      schoolTypes.push("高");
    }
    if (examRooms.length > 0) {
      return `${item.schoolName}[${schoolTypes.toString()}](${examRooms
        .map((item) => {
          return item.examRoomName;
        })
        .toString()}) / ${item.standardExamStudents}人`;
    }
    return `${item.schoolName}[${schoolTypes.toString()}] / ${item.standardExamStudents}人`;
  }

  private beginDataChange(times: { getTime: () => number }) {
    if (this.editModel) {
      this.fromExamProject.examEndTime = "";
      this.editModel = false;
    }
    if (times) {
      this.dateEnd = {
        disabledDate(time: { getTime: () => number }) {
          return time.getTime() < times.getTime();
        },
      };
    }
  }
  private endDataChange(times: { getTime: () => number }) {
    if (this.editModel) {
      this.fromExamProject.examStartTime = "";
      this.editModel = false;
    }
    if (times) {
      this.dateBegin = {
        disabledDate(time: { getTime: () => number }) {
          return time.getTime() > times.getTime();
        },
      };
    }
  }

  private closeShowDialog() {
    this.showDialogImport = false;
    this.showDialogImportType = "";
  }

  private async initExamProjectProfile(examProjectId: string) {
    const res = await queryExamProjectProfile(examProjectId);
    for (let i = 0; i < res.data.examPapers.length; i++) {
      const examPaper = res.data.examPapers[i];
      let existsExamPaperFile: boolean = false;
      try {
        const fileNameParts = examPaper.examPaperFileName.split("\\");
        if (fileNameParts.length) {
          const { data } = await getExamPapers({ fileName: fileNameParts[fileNameParts.length - 1] });
          existsExamPaperFile = data.items.length > 0;
        }
      } finally {
        res.data.examPapers[i] = _.merge(
          new ExamPaper(),
          examPaper,
          { existsExamPaperFile }
        );
        console.log(`examPapers: ${JSON.stringify(res.data.examPapers[i])}`);
      }
    }
    this.fromExamProject = _.merge(new ExamProjectPro(), res.data);

  }

  mounted() {

    this.pageType = (this.$route.query.type as string) || "add";
    if (this.$route.query.examProjectId) {
      this.initExamProjectProfile(this.$route.query.examProjectId as string).then(() => {
        this.$nextTick();
      });

    }
  }
  private doGoBackClick() {
    this.$router.go(-1);
  }
}
